import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import { Account, AccountCollection } from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<Account, AccountCollection>
>;

@Component
export default class AccountsMixin extends Mixins(TypedModelMixin) {
  obCollection = new AccountCollection();
  obModelClass = Account;
  obCollectionClass = AccountCollection;
  sRoutePath = "/accounts";

  created() {
    this.onCreated();
  }
}
